export const LOGON_FORM_TITLE = "MPS Core Reports Login"; // may not be used...
export const LOCAL_STORAGE_USER = "user-info";
export const LOCAL_STORAGE_FACILITY_SELECTIONS = "facility-selections";
export const LOCAL_STORAGE_LAST_ACTIVITY = "last-activity";
export const LOCAL_STORAGE_OKTA_REGULAR_FLAG = "di-okta-regular-flag";  //regular = non federated, will be null if federated login; Only used for Okta
export const LOCAL_STORAGE_OKTA_SUPPORT_REALM = "di-okta-support-realm";
export const LOCAL_STORAGE_OKTA_SELECTED_USER = "di-okta-selected-user";
export const REFRESH_NOTIFICATIONS = "refresh-notifications";
export const IDLE_WARNING_TIME = Number(process.env.REACT_APP_IDLE_WARNING_TIME); //milliseconds
export const IDLE_LOGOUT_TIME = Number(process.env.REACT_APP_IDLE_LOGOUT_TIME); //milliseconds
export const NOTIFICATIONS_REFRESH = Number(900000); //milliseconds, staring with 15 minutes can be updated
export const WEBAPP_VERSION = "v4.0";
export const OKTA_CALLBACK_PATH = '/okta_login/callback';
export const OKTA_RESTORE_URI = '/okta_login/return';
export const OKTA_LOGOUT_URI = '/okta_logout';
export const MODELS_WITH_FACILITY_SELECTION = ["star-schema", "apsmain"];
export const SINGLE_SCHEDULE_RUN_COUNTDOWN = 300000; //milliseconds, 5 minutes
export const GLOBAL_SCHEDULE_RUN_COUNTDOWN = 5000;